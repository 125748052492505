import gql from "graphql-tag";
import { FRAGMENT_ORGANISMS } from "./fragments.js";

export const GET_ORGANISMS = gql`
  ${FRAGMENT_ORGANISMS}
  query GetOrganisms($uri: String!) {
    organisms: nodeByUri(uri: $uri) {
      ...OrganismsFragment
    }
  }
`;
